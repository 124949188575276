import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { NSClass } from 'gga-types'
import { getUserClassesApi } from '@/services/class'
import { request } from '@/utils/request'
import { APP_LOCALE } from '@/constants/define'
import _ from 'lodash'

export type ICourseState = ReturnType<typeof useCourseState>

export default function useCourseState() {
  const [userTeamClasses, setUserTeamClasses] = useState<NSClass.ClassItem[]>(
    []
  )

  const [loading, setLoading] = useState(false)
  const [joinedClasses, setJoinedClasses] = useState<NSClass.UserJoinedClass[]>(
    []
  )

  const fetchUserTeamClasses = useCallback(async () => {
    try {
      setLoading(true)
      const ret = await getUserClassesApi({ category: 'Team' })
      if (ret && ret.data && ret.data.data) {
        setUserTeamClasses(ret.data.data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }, [])

  const userClassSet = useMemo(
    () => new Set((userTeamClasses || []).map(i => i.id)),
    [userTeamClasses]
  )

  const isJoined = useCallback(
    (id: number) => {
      return userClassSet.has(id)
    },
    [userClassSet]
  )

  const fetchUserJoinedClasses = useCallback(async () => {
    setLoading(true)
    const ret = await request({ url: `/v2/classes/joined/${APP_LOCALE}` })
    if (ret && ret.data && ret.data.data) {
      // console.log('================', JSON.stringify(ret.data.data))

      ret.data.data = _.uniqBy(ret.data.data, 'id')

      setJoinedClasses(ret.data.data)
    }
    setLoading(false)
  }, [])

  return {
    loading,
    isJoined,
    fetchUserTeamClasses,
    userTeamClasses,
    fetchUserJoinedClasses,
    joinedClasses,
  }
}
