import router from 'next/router'
import { UserWebUrl } from '@/config/config'
import { TAppLocale, TAppType } from '@/@types/global'

export const APP_TYPE = process.env.APP_TYPE as TAppType
export const APP_LOCALE = process.env.APP_LOCALE as TAppLocale
export const WEB_HOST = (() => {
  return UserWebUrl[APP_LOCALE]
})()

export const NodeType = {
  SoloTask: 'solo_task',
  TeamScrim: 'team_scrim',
  PageStd: 'page_std',
  Seminar: 'seminar',
}

export function ConvertTeamStatus(status) {
  switch (status) {
    case 'submitted':
      return 'Coach feedback coming soon!'
    case 'failed':
      return 'Coach Feedback Failed'
    case 'finished':
      return 'Coach Feedback Finished'
    default:
      return 'Recording Needed'
  }
}

export const AvatarList = [
  'https://res.gengesports.cn/avatars/1.png',
  'https://res.gengesports.cn/avatars/2.png',
  'https://res.gengesports.cn/avatars/3.png',
  'https://res.gengesports.cn/avatars/4.png',
  'https://res.gengesports.cn/avatars/5.png',
  'https://res.gengesports.cn/avatars/6.png',
  'https://res.gengesports.cn/avatars/7.png',
  'https://res.gengesports.cn/avatars/8.png',
]
export const DefaultAvatar = AvatarList[0]

export const REFERRER = 'referrer'
export const REFERRER_ENTRANCE = 'referrer_entrance'

export const HOMEPAGE_DYNAMIC = (i18n?: any) => {
  switch (APP_LOCALE) {
    case 'en_US':
      return 'https://www.ggacademy.gg/?lang=en'
    default:
      return '/'
  }
}

export const DASHBOARD_DYNAMIC = () => {
  return '/'
}

export const SITE_NAME = {
  kr: '젠지 글로벌 아카데미',
  zh_CN: 'Gen.G国际电竞人才培养平台',
  en_US: 'Gen.G Global Academy',
}

export const NO_AUTH_ROUTE = [
  /^\/$/,
  /^\/home$/,
  /^\/login$/,
  /^\/login\/sms$/,
  /^\/forget-password$/,
  /^\/register$/,
  /^\/_report$/,
  /^\/buy-now$/,
  /^\/h5\/.*$/g,
  /^\/course-center$/,
  /^\/certificate-validate\/_generator$/,
  /^\/inquiry([?].*)?$/,
  /^\/about\/.*$/g,
  /^\/cashier\$/,
]

export function isNoAuthRoute() {
  return NO_AUTH_ROUTE.some(reg => reg.test(router.pathname))
}

export const URL_SCHEME_CLIENT_UUID_KEY = '_scheme_uid'

export const GOOGLE_RECAPTCHA_KEY = '6LdbC7gqAAAAADTQRdAKXu6txxUBCgnSWhgjHu4t'

export const LangCookieName = '_lang_code'
export const LEGACY_BROWSER_REDIRECT_KEY = 'f'
